<app-loading *ngIf="loading" class="full-loader"></app-loading>
<app-hol-modal
  (changeItems)="onChangeHolModalConfig($event)"
  (saveItem)="saveLogbook($event)"
  *ngIf="config$ | async as config"
  [canArchive]="false"
  [canEditTitle]="!isHistory"
  [canUpdateFromOtherModule]="canUpdateFromOtherModule"
  [config$]="config$"
  [config]="config"
  [contentTextLength]="200"
  [context]="context"
  [fastMode]="isFastTyping()"
  [historyItemComponent]="data.itemComponent"
  [historyItemListMode]="true"
  [historyItemObjectId]="logbook.objectId"
  [historyItemType]="'logbook'"
  [historyTitle]="moduleConfig.config.translateKey + '.MODALS.HISTORY.LOGBOOK'"
  [isCreate]="isCreate"
  [isHistory]="isHistory"
  [isReadOnly]="isReadOnly || isHistory"
  [item]="logbook"
  [limitTo]="limitTo"
  [notifications]="notifications"
  [type]="'LOGBOOK'"
>
  <ng-container class="template_GROUP_LINK">
    <app-group-selector
      (groupChange)="updateLinkedGroup($event)"
      [disabledLinks]="disabledLinks"
      [isReadOnly]="isReadOnly"
      [listGroup]="logbookGroup"
      [modeLinkIsActivate]="modeLinkIsActivate"
      [type]="'logbook'"
      class="contentSectionAdd"
    ></app-group-selector>
  </ng-container>

  <ng-container class="template_GROUP_DECISION contentSectionAdd">
    <div *ngIf="!isFromDecision" class="contentSectionAdd">
      <div *ngIf="linkedDecisions; else linkedDecisionsLoadingTemplate" class="linked-section">
        <div class="linked-section__top">
          <div class="left">
            <span *ngIf="(isReadOnly || modeLinkIsActivate) && linkedDecisionsDisplay.length" class="title-read-only">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_LABEL' | translate }}</mat-label>
              <mat-select
                (ngModelChange)="changelinkedDecision($event)"
                [(ngModel)]="linkedDecisions"
                [disabled]="decisionItems.length === 0"
                [ngModelOptions]="{ standalone: true }"
                multiple
                ngModel
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <div *ngFor="let decision of decisionItems">
                  <mat-option *ngIf="decision.message" [value]="decision.objectId">{{ decision.message }}</mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              (click)="addDecision($event)"
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate && moduleConfig.config.canCreateItemsFromLogbook"
              class="add-button mat-icon-button"
              color="primary"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let decision of linkedDecisionsDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ decision.message }}
              <span *ngIf="disabledLinks[decision.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                (click)="removeDecision(decision)"
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[decision.objectId]"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedDecisionsLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <ng-container class="template_FLIGHT_LINK contentSectionAdd">
    <!-- LINKED OCC FLIGHT -->
    <div class="contentSectionAdd">
      <div *ngIf="moduleConfig.config.showLinkToFlightOcc" class="hol-dialog-section">
        <div class="linked-flight">
          <label id="favoriteStateLabel">{{
            moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.FLIGHT' | translate
          }}</label>
          <div class="date input-group">
            <mat-form-field class="full-width no-label">
              <input
                (ngModelChange)="changeDate($event)"
                [disabled]="isReadOnly"
                [matDatepicker]="flightPicker"
                [ngModelOptions]="{ name: 'utc', standalone: true }"
                [ngModel]="searchData.date"
                matInput
                placeholder="DD/MM/YYYY"
                tabindex="-1"
              />
              <mat-datepicker-toggle [for]="flightPicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #flightPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="flight">
            <mat-form-field>
              <span class="icon-container" matPrefix>
                <i class="hol-icon-airplane-mode-active"></i>
              </span>
              <input
                (ngModelChange)="querySearch($event)"
                [disabled]="isReadOnly || !searchData.date || searchData.flights.length === 0"
                [matAutocomplete]="auto"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="searchData.flightNumber"
                [value]="searchData.flightNumber"
                matInput
                name="searchDataFight"
                placeholder="{{ autocompletePlaceHolder }}"
                type="text"
              />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedItemChange($event)">
                <mat-option *ngFor="let item of searchData.flightsFiltered" [value]="item.objectId">
                  {{ item.flightNumber }} {{ item.std | date : 'HH:mm ddMMM' : 'UTC' }}
                </mat-option>
                <mat-option *ngIf="searchData.flightsFiltered.length === 0 && searchData.date"
                  >No results found with this search.
                </mat-option>
              </mat-autocomplete>
              <button
                (click)="resetFlight()"
                *ngIf="selectedFlight && !isReadOnly"
                aria-label="Clear"
                mat-icon-button
                mat-icon-button
                matSuffix
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="template_EVENT_LINK contentSectionAdd">
    <div *ngIf="!isFromDecision && moduleConfig.config.moduleName !== 'ecl'" class="hol-dialog-section contentSectionAdd">
      <div *ngIf="linkedEvents; else linkedEventLoadingTemplate" class="linked-section">
        <div class="linked-section__top">
          <div class="left">
            <span *ngIf="(isReadOnly || modeLinkIsActivate) && linkedEventsDisplay.length" class="title-read-only">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate }}</mat-label>
              <mat-select
                (ngModelChange)="changelinkedEvent($event)"
                [(ngModel)]="linkedEvents"
                [disabled]="events.length === 0"
                [ngModelOptions]="{ standalone: true }"
                multiple
                ngModel
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let event of events" [value]="event.objectId"
                  >{{ event.scenario.code + event.order + ' - ' + event.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              (click)="addEvent()"
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate && moduleConfig.config.canCreateItemsFromLogbook"
              class="add-button mat-icon-button"
              color="primary"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let event of linkedEventsDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ event.scenario.code + event.order + ' - ' + event.description }}
              <span *ngIf="disabledLinks[event.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                (click)="removeEvent(event)"
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[event.objectId]"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedEventLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <div class="logbook-modal">
    <!-- TRANSFORM TO DECISION
    <div *ngIf="!isCreate" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="changeToDecision"
        [disabled]="logbook.readOnly || isReadOnly || (data.isSummaryActivated && isInSummary)"
        [labelPosition]="'after'"
        color="primary"
      >
        Transform to decision
      </mat-checkbox>
    </div>
    -->
    <!-- FROM OTHER MODULE -->
    <div *ngIf="logbook.fromGOC" class="hol-dialog-section">
      <mat-checkbox [(ngModel)]="fromGOC" [disabled]="isHistory || logbook.readOnly" [labelPosition]="'after'" color="primary">
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.FROM_GOC' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="logbook.fromCREW" class="hol-dialog-section">
      <mat-checkbox [(ngModel)]="fromCREW" [disabled]="isHistory || logbook.readOnly" [labelPosition]="'after'" color="primary">
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.FROM_CREW' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="logbook.fromERP && hasCrisisInProgress" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="fromERP"
        [disabled]="isHistory || logbook.readOnly || isReadOnly"
        [labelPosition]="'after'"
        color="primary"
      >
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.FROM_ERP' | translate }}
      </mat-checkbox>
    </div>
    <!-- LINKED GROUP
    <ng-template #template_GROUP_LINK>
      <app-group-selector
        (groupChange)="updateLinkedGroup($event)"
        [disabledLinks]="disabledLinks"
        [isReadOnly]="isReadOnly"
        [listGroup]="logbookGroup"
        [modeLinkIsActivate]="modeLinkIsActivate"
        [type]="'logbook'"
      ></app-group-selector>
    </ng-template>
    -->

    <!-- LINKED EVENTS -->
    <!-- LINKED DECISIONS -->
  </div>
  <!-- COLUMN RIGHT -->
  <div class="content-right-top-addon">
    <!-- ON Yellow SUMMARY
    <app-ocl-summary *ngIf="data.isSummaryActivated" [(isInBriefing)]="isInBriefing"
                     [(isInSummary)]="isInSummary"></app-ocl-summary>
    -->
  </div>
  <!-- PINNED BTN
  <div class="actions-right-addon">
    <div *ngIf="!isReadOnly" [ngClass]="{ isPinned: logbook.isPinned }" class="pinned-item-btn">
      <mat-checkbox [(ngModel)]="logbook.isPinned" aria-label="is Pinned" color="primary">
        <i class="hol-icon-pin"></i>
      </mat-checkbox>
    </div>
    <div *ngIf="isReadOnly && logbook.isPinned" class="pinned-item-btn isPinned">
      <i class="hol-icon-pin"></i>
    </div>
  </div>
  -->
</app-hol-modal>
